(function(app)
{
    app.controller('DashboardCtrl', [
        '$scope', 'api', 'alerts',
        function($scope, api, alerts)
        {
            $scope.sales_loading = true;
            $scope.customers_loading = true;
            $scope.average_ticket_loading = true;
            $scope.services_loading = true;
            $scope.products_loading = true;
            $scope.view_daily_stats = true;
            $scope.time_frame = 'day';

            $scope.selected_year = moment().format('Y');
            $scope.selected_week = (moment().format('W'));
            $scope.selected_day = moment().format('E');
            $scope.getDashboardData = function() {
                var data = {
                    year: $scope.selected_year,
                    day: $scope.selected_day,
                    week: $scope.selected_week,
                    store: $scope.store
                };

                if(data.year == null || data.day == null || data.week == null)
                    return;

                $scope.sales_loading = true;
                $scope.customers_loading = true;
                $scope.average_ticket_loading = true;
                $scope.services_loading = true;
                $scope.products_loading = true;


                api.getSalesData(data)
                    .then(function(data)
                    {
                        $scope.sales = data;
                        $scope.sales_loading = false;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
                api.getCustomerData(data)
                    .then(function(data)
                    {
                        console.log('got data?');
                        $scope.customers = data;
                        $scope.customers_loading = false;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
                api.getAvgData(data)
                    .then(function(data)
                    {
                        $scope.average_ticket_loading = false;
                        $scope.average = data;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
                api.getServicesData(data)
                    .then(function(data)
                    {
                        $scope.services_loading = false;
                        $scope.services = data;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
                api.getProductsData(data)
                    .then(function(data)
                    {
                        $scope.products_loading = false;
                        $scope.products = data;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
                //$scope.getWeeks();
            };
            $scope.getDashboardCumulativeData = function() {
                var data = {
                    year: $scope.selected_year,
                    day: $scope.selected_day,
                    store: $scope.store,
                    period: $scope.period,
                    "time_frame": $scope.time_frame
                };

                if(data.year == null || data.day == null)
                    return;

                $scope.sales_loading = true;

                api.getCumulativeSalesData(data)
                    .then(function(data)
                    {
                        $scope.sales_loading = false;
                        $scope.sales = data;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });

            };
            $scope.getYears = function () {
                api.getYears()
                    .then(function(results)
                    {
                        $scope.years = results;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', 'Error obtaining data!');
                    });
            };
            $scope.getWeeks = function () {

                api.getWeeks($scope.selected_year)
                    .then(function(results)
                    {
                        $scope.weeks = results;
                        if($scope.obtaining_weeks)
                            $scope.getData();
                        $scope.obtaining_weeks = false;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', 'Error obtaining data!');
                    });
            };
            $scope.getStores = function () {

                api.getStores()
                    .then(function(results)
                    {
                        $scope.stores = results;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
            };
            $scope.getDays = function() {
                api.getDays()
                    .then(function(results)
                    {
                        $scope.days = results;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
            };

            $scope.getDayOfWeek = function() {
                var day = Number(moment().subtract(1,'days').format('E'));

                if(day == 7)
                    $scope.selected_week = ($scope.selected_week - 1).toString();

                return Number(moment().subtract(1,'days').format('E'));
            };
            var weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            $scope.weekday = function(day) {
                return weekdays[day - 1];
            };

            $scope.obtaining_weeks = false;
            $scope.$watch('selected_year', function(newValue, oldValue) {
                if ((Number(newValue) != Number(oldValue)) && !$scope.obtaining_weeks) {
                    $scope.obtaining_weeks = true;
                    $scope.getWeeks();
                }
            },true);
            var init = false;
            $scope.$watch('selected_week', function(newValue, oldValue) {



                if(init) {
                    $scope.selected_day = 7;
                }
                else
                    init = true;
                if($scope.selected_week == $scope.init_week)
                {
                    if($scope.init_day - 1 != 0)
                        $scope.selected_day = $scope.init_day - 1;
                    else
                        $scope.selected_day = 7;


                }
            },true);
            $scope.$watchGroup(["selected_day","selected_week", "store", "period", "time_frame"], function(newValues, oldValues)
            {
                $scope.getData();
            }, true);

            $scope.getYears();
            $scope.getWeeks();
            $scope.getStores();
            // $scope.getDashboardData();

            $scope.init_year = moment().format('Y');
            $scope.init_week = (moment().format('W'));
            $scope.init_day = moment().format('E');

            $scope.number_format = function(value, point)
            {
                if(value == null)
                    return 0;
                return Number(Number(value).toFixed(point)).toLocaleString();
            };
            $scope.getData = function () {

                if ($scope.time_frame === "day") {
                    $scope.getDashboardData();
                    return;
                }
                $scope.getDashboardCumulativeData($scope.time_frame);
            }

        }
    ]);
})(window.dy_n_fly_scoreboard);