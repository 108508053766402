'use strict';


(function (app)
{

    app.factory('api', [
        '$rootScope', '$http', '$location', '$q', 'alerts',
        function ($rootScope, $http, $location, $q, alerts)
        {
            var rootpoint = '/api';
            var apiFactory = {};

            apiFactory.isAuthenticated = function ()
            {
                return doRequest('auth', 'authenticated', 'get');
            };

            apiFactory.sendAuthentication = function (data)
            {
                return doRequest('auth', 'login', 'post', data);
            };
            apiFactory.revokeAuthentication = function ()
            {
                return doRequest('auth', 'logout', 'post', []);
            };
            apiFactory.getPasswordResetRequest = function (data)
            {
                return doRequest('auth', 'request-password-forgot', 'get', data);
            };
            apiFactory.getCheckPasswordResetToken = function (data)
            {
                return doRequest('auth', 'check-password-reset-token', 'get', data);
            };
            apiFactory.postUpdatePassword = function (data)
            {
                return doRequest('auth', 'update-password', 'post', data);
            };
            apiFactory.postUpdateUserPassword = function (data)
            {
                return doRequest('user', 'update-user-password', 'post', data);
            };
            apiFactory.getResetPassword = function (data)
            {
                return doRequest('auth', 'reset-password', 'get', data);
            };
            //----------- Users
            apiFactory.searchUsers = function(data)
            {
                return doRequest('user', 'search-users', 'post', data);
            };
            apiFactory.postGetUserById = function(id)
            {
                return doRequest('user', 'get-user-by-id', 'post', {id:id});
            };
            apiFactory.getCurrentUser = function ()
            {
                return doRequest('user', 'current-user', 'get', []);
            };
            apiFactory.getAllUsers = function (data)
            {
                return doRequest('user', 'all-users', 'post', data);
            };
            apiFactory.postAddUser = function (data)
            {
                return doRequest('user', 'add-user', 'post', data);
            };
            apiFactory.postEditUser = function (data)
            {
                return doRequest('user', 'edit-user', 'post', data);
            };
            apiFactory.postDeleteUser = function (id)
            {
                return doRequest('user', 'delete-user', 'post', {id: id});
            };
            //---------- Data
            apiFactory.getSalesData = function(data)
            {
                return doRequest('sales', 'sales', 'get', data);
            };
            apiFactory.getCumulativeSalesData = function(data)
            {
                return doRequest('sales', 'cumulative-sales', 'get', data);
            };
            apiFactory.getCustomerData = function(data)
            {
                return doRequest('sales','customer', 'get', data);
            };
            apiFactory.getAvgData = function(data)
            {
                return doRequest('sales', 'average-ticket', 'get', data);
            };
            apiFactory.getServicesData = function(data)
            {
                return doRequest('sales', 'service-traffic', 'get', data);
            };
            apiFactory.getProductsData = function(data)
            {
                return doRequest('sales', 'product-traffic', 'get', data);
            };
            //----------- Info
            apiFactory.getWeeks = function(year)
            {
                return doRequest('info', 'weeks', 'get', {year:year});
            };
            apiFactory.getYears = function()
            {
                return doRequest('info', 'years', 'get', []);
            };
            apiFactory.getDays = function()
            {
                return doRequest('info', 'days', 'get', []);
            };
            apiFactory.getStores = function()
            {
                return doRequest('info', 'stores', 'get', []);
            };

            return apiFactory;


            function doRequest(midpoint, endpoint, method, data)
            {
                var url = rootpoint + '/' + midpoint + '/' + endpoint;
                var cancel_q = $q.defer();

                var q = $q(function (resolve, reject)
                {
                    switch (method)
                    {
                        case 'get':
                            return $http.get(url, {
                                params: data,
                                timeout: cancel_q.promise
                            }).then(function (data, status, headers)
                            {
                                onValid.call(this, data.data);
                                resolve.call(this, data.data.data);
                            }).catch(function (data)
                            {
                                onError.call(this, data);
                                if (data.data && data.data.hasOwnProperty('error'))
                                {
                                    reject.call(this, data.data.error);
                                } else
                                {
                                    reject.call(this, 'Offline');
                                }
                            });
                            break;
                        case 'post':
                            return $http.post(url, data, {timeout: cancel_q.promise}).then(function (data, status, headers)
                            {
                                onValid.call(this, data.data);
                                resolve.call(this, data.data.data);
                            }).catch(function (data)
                            {

                                onError.call(this, data);
                                if (data.data && data.data.hasOwnProperty('error'))
                                {
                                    reject.call(this, data.data.error);
                                } else
                                {
                                    reject.call(this, 'Offline');
                                }
                            });
                            break;
                    }
                });

                q.cancel = function (reason)
                {
                    cancel_q.resolve(reason);
                };

                return q;
            }

            function doRequestFile(midpoint, endpoint, file, type)
            {
                var url = rootpoint + '/' + midpoint + '/' + endpoint;

                var fd = new FormData();
                fd.append('file', file);
                if (type)
                {
                    fd.append('type', type);
                }

                return $q(function (resolve, reject)
                {
                    $http.post(url, fd, {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': undefined}
                    }).then(function (data, status, headers)
                    {
                        onValid.call(this, data.data);
                        resolve.call(this, data.data.data);
                    }).catch(function (data)
                    {
                        onError.call(this, data);
                        reject.call(this, data.data.error);
                    });
                });
            }

            function onValid(data)
            {
                if (data.hasOwnProperty('immediate_action') && data.immediate_action)
                {
                    console.log(data.immediate_action);
                }
            }

            function onError(data)
            {

                if (data.status == 401)
                {
                    $rootScope.$broadcast('authenticated', false);
                    //$location.path('/login');
                }
            }

        }
    ]);


})(window.dy_n_fly_scoreboard);