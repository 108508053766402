(function (app)
{
    app.service('user', [
        'api',
        function (api)
        {
            var self = this;

            self.permissions = {
                admin: 'ADMIN',
                scheduler: 'SCHED',
                supervisor: 'SUPER',
                quality_control: 'QC'
            };

            self.authenticated = false;
            self.auth_error_msg = "";
            self.user = false;
            self.user_permissions = [];

            getIsAuthenticated();

            self.authenticateUser = function (email, password)
            {
                return api
                    .sendAuthentication({
                        email: email,
                        password: password
                    })
                    .then(function (response)
                    {
                        if (response.authenticated)
                        {
                            self.authenticated = true;
                            self.auth_error_msg = "";
                            self.getCurrentUser();
                            return true;
                        }
                        else
                        {
                            self.authenticated = false;
                            self.auth_error_msg = response.error;
                            return false;
                        }
                    });
            };

            self.revokeAuthentication = function ()
            {
                return api.revokeAuthentication()
                    .then(function ()
                    {
                        self.user = false;
                        self.authenticated = false;
                    });
            };

            self.hasPermission = function (permission)
            {
                if (Array.isArray(permission))
                {
                    if (permission.length == 0) return true;

                    return permission.reduce(function (prev, curr){
                        return prev || self.hasPermission(curr);
                    }, false);
                }
                else
                {
                    return self.user_permissions.indexOf(permission) !== -1;
                }
            };

            self.getCurrentUser = function()
            {
                api
                    .getCurrentUser()
                    .then(function (result)
                    {
                        self.user = result.user;
                        self.user_permissions = [];

                       /* for (var i = 0; i < result.user.user_type.length; i++)
                        {
                            self.user_permissions.push(result.user.user_type[i].type.user_code)
                        }*/
                    });
            };

            function getIsAuthenticated()
            {
                api.isAuthenticated()
                    .then(function (result)
                    {
                        if (result.authenticated)
                        {
                            self.authenticated = true;
                            self.getCurrentUser();
                        }
                        else
                        {
                            self.authenticated = false;
                        }
                    });
            }
        }
    ]);
})(window.dy_n_fly_scoreboard);
