/**
 * Created by mwillard on 2/2/17.
 */
(function(app)
{
    app.controller('UsersCtrl',[
        '$scope', 'api', 'alerts','forms','user',
        function($scope, api, alerts, forms, user_service)
        {
            $scope.getUsers = function(tablestate)
            {
                $scope.isLoading = true;

                if(!tablestate)
                    return;
                tablestate.search.user_type = $scope.user_type_search;
                $scope.tablestate = tablestate;
                api
                    .searchUsers(tablestate)
                    .then(function(results)
                    {
                        $scope.isLoading = false;
                        $scope.displayed = [].concat(results.records);
                        tablestate.pagination.numberOfPages = Math.ceil(results.total / tablestate.pagination.number);
                    })
                    .catch(function(results)
                    {
                        alerts.error('Error',results);
                    });

            };
            $scope.addUser = function()
            {
                forms.addUser('Add User', function(user, $uibmodal)
                {
                    api
                        .postAddUser({user:user})
                        .then(function(results)
                        {
                            $uibmodal.dismiss();
                            alerts.success('User Created', 'User: ' + user.name + ', has been created!');

                            $scope.getUsers($scope.tablestate);
                        })
                        .catch(function(error)
                        {
                            alerts.error('Error', error);
                        })
                });
            };
            $scope.viewUser = function(id)
            {
                api
                    .postGetUserById(id)
                    .then(function(response)
                    {
                        forms.editUser(response.user, function(user, $uibmodal, remove_user)
                        {
                            if(remove_user)
                            {
                                api.postDeleteUser(user.id)
                                    .then(function()
                                    {
                                        $uibmodal.dismiss();
                                        alerts.success('Success', 'User has been removed!');
                                        $scope.getUsers($scope.tablestate);
                                    })
                                    .catch(function(error)
                                    {
                                        alerts.error('Error', error);
                                    });

                                return;
                            }
                            api
                                .postEditUser({user:user})
                                .then(function(response)
                                {
                                    $uibmodal.dismiss();
                                    alerts.success('User Updated', 'User: ' + user.name + ', was updated!');
                                    user_service.getCurrentUser();
                                    $scope.getUsers($scope.tablestate);
                                })
                                .catch(function(error)
                                {
                                    alerts.error('Error', error);
                                });
                        });
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
            };
        }
    ])
})(window.dy_n_fly_scoreboard);
