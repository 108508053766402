/**
 * Created by mwillard on 1/17/17.
 */
(function(app)
{
    app.controller('AppCtrl',[
        '$scope', 'api', 'user', 'alerts','$location','$rootScope',
        function($scope, api, user, alerts, $location, $rootScope)
        {
            $scope.original_path = $location.path();
            $scope.menus = [
                {
                    name:'Dashboard',
                    href:'/dashboard',
                    admin:false,
                    items:[]
                },
                /*{
                    name:'Projections',
                    href:'/projections',
                    items:[]
                },
                {
                    name:'Reports',
                    href:'/reports',
                    items:[]
                },*/
                {
                    name:'Admin',
                    href:'#',
                    admin:true,
                    items:[
                        {
                            name:'Users',
                            href:'/users'
                        },
                       /* {
                            name:'Permissions',
                            href:'/permissions'
                        },
                        {
                            name:'Stores',
                            href:'/stores'
                        },
                        {
                            name:'Labor Goals',
                            href:'/labor-goals'
                        },*/
                        {
                            name:'Data Upload',
                            href:'/data-upload'
                        }
                    ]
                }
            ];

            $scope.login_out_sites = ['(\/login\/)','(\/password-reset)'];
            $scope.login_out_sites_reg = new RegExp($scope.login_out_sites.join('|'));
            $scope.non_auth_sites = ['(\/password-reset\/)','(\/test)'];
            $scope.non_auth_sites_reg = new RegExp($scope.non_auth_sites.join('|'));

            $scope.user = user;

            $scope.$on('$locationChangeSuccess', function (event, location)
            {
                $scope.use_padding = true;

                if (user.authenticated && ($rootScope.original_path == '/login' || $rootScope.original_path == '/logout' || $scope.non_auth_sites_reg.test($location.path())))
                {
                    $location.path('/dashboard');
                }
                for (var i in $scope.menus)
                {
                    var item = $scope.menus[i];

                    var reg = new RegExp(item.href);

                    if (reg.test(location))
                    {
                        item.active = true;
                    }
                    else {
                        item.active = false;
                    }
                    if (item.hasOwnProperty('items'))
                    {
                        for (var ii in item.items)
                        {
                            var item2 = item.items[ii];

                            var reg2 = new RegExp(item2.href);

                            if (reg2.test(location))
                            {
                                item2.active = true;
                            }
                            else
                            {
                                item2.active = false;
                            }
                        }
                    }

                }
                /*if(user.authenticated) {
                    user.fetchCurrentUser().then(function (results) {
                        var menu_item = getMenuObject($location.path());
                        console.log(menu_item);
                        if (menu_item != null && $location.path() != '/password' && !user.hasPermission(menu_item.user_rights)) {
                            alerts.error('Lacking Rights!', 'You do not have the rights to access this page!');
                            $location.path('/dashboard');
                        }

                    }).catch(function () {
                        console.log('Error with user!');
                        alerts.error('Lacking Rights!', 'You do not have the rights to access this page!');
                        $location.path('/dashboard');
                    })
                }*/


            });


            //Quick fix for login errors, service runs after this, causing missing permissions.
            $rootScope.original_path = $location.path();//'/home';

            $scope.$watch('user.authenticated', function (authenticated)
            {
                if (authenticated)
                {
                    if ($rootScope.original_path == '/login' || $rootScope.original_path == '/logout' || $scope.non_auth_sites_reg.test($location.path()))
                    {
                        $rootScope.original_path = '/dashboard';
                    }

                    $location.path($rootScope.original_path).replace();
                }
                else
                {

                    if ($scope.non_auth_sites_reg.test($location.path()))
                        return;

                    var cpath = $location.path();

                    if (cpath != '/login' && cpath != '/logout')
                    {
                        $rootScope.original_path = cpath;

                    }

                    $location.path('/login').replace();
                }
            });

            if (!$scope.non_auth_sites_reg.test($location.path()))
            {
                $location.path('/login').replace();
            }
        }
    ]);
})(window.dy_n_fly_scoreboard);