/**
 * Created by mwillard on 1/18/17.
 */
(function(app)
{
    app.controller('LoginCtrl', [
       '$scope', 'alerts', 'api','user',
        function($scope, alerts, api, user)
        {
            $scope.user = {};
            $scope.submit = function()
            {
                user.authenticateUser($scope.user.email, $scope.user.password)
                    .then(function(authenticated)
                    {
                        if(!authenticated)
                            alerts.error('Error', 'Incorrect login credentials!');
                    });

            };
        }
    ]);
})(window.dy_n_fly_scoreboard);