'use strict';


(function (app)
{

    app.factory('forms', [
        '$uibModal',
        function ($uibModal)
        {
            var forms = {};

            forms.addUser = function(title, callback)
            {
                var modalInstance = $uibModal.open({
                    templateUrl: "/templates/formPopUps/create_user.html",
                    size:'lg',
                    windowTemplateUrl: "/templates/modalTemplate.html",
                    controller: 'ModUserCtrl',
                    resolve: {
                        title: function() { return title; },
                        user: function() { return null; },
                        callback:function() {return callback;}
                    }
                });
            };
            forms.editUser = function(user_data, callback)
            {
                var modalInstance = $uibModal.open({
                    templateUrl: "/templates/formPopUps/edit_user.html",
                    size:'lg',
                    windowTemplateUrl: "/templates/modalTemplate.html",
                    controller: 'ModUserCtrl',
                    resolve: {
                        title: function() { return 'Edit User'; },
                        user:function() {return user_data;},
                        callback:function() {return callback;}
                    }
                });
            };
            forms.checkUser = function(callback)
            {
                var modalInstance = $uibModal.open({
                    templateUrl: "/templates/formPopUps/check_user.html",
                    size:'md',
                    windowTemplateUrl: "/templates/modalTemplate.html",
                    controller: 'CheckUserCtrl',
                    resolve: {
                        callback:function() {return callback;}
                    }
                });
            };
            forms.schedulerAdder = function()
            {

            };

            return forms;
        }
    ]);


})(window.dy_n_fly_scoreboard);