/**
 * Created by mwillard on 2/2/17.
 */
(function(app)
{
    app.controller('ModUserCtrl', [
        '$scope', '$uibModalInstance', 'alerts', 'title', 'user', 'callback',
        function($scope,$uibModalInstance, alerts, title, user, callback)
        {
            $scope.title = title;
            //$scope.user = user ? user : {user_type_code:'ADMIN'};
            if(user != null) {
                $scope.user = user;
            }
            else
                $scope.user = {
                };

            $scope.edit = user ? true : false;

            $scope.submit = function()
            {
                if($scope.edit) {
                    alerts.confirm('Are you sure?', 'This will update the current user. Would you like to continue?', function () {
                        callback($scope.user, $uibModalInstance);
                    }, function () {
                    });
                }
                else
                    callback($scope.user, $uibModalInstance);
            };
            $scope.dismiss = $uibModalInstance.dismiss;
            $scope.removeUser = function()
            {
                alerts.confirm('Are you Sure?', 'This will remove the user from the system! Are you sure you want to continue?', function()
                {
                    callback($scope.user, $uibModalInstance, true);
                });

            };

        }
    ]);
})(window.dy_n_fly_scoreboard);