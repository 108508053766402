/**
 * Created by mwillard on 1/25/17.
 */
(function(app)
{
    app.controller('FileUploadCtrl', [
       '$scope', 'Upload','alerts',
        function($scope, Upload, alerts)
        {
            $scope.uploading = false;
            $scope.file = null;
            $scope.upload = function(file)
            {
                if($scope.uploading)
                    return;
                $scope.uploading = true;
                console.log(file, $scope.file);
                if(file == null)
                {
                    alerts.error('Error', 'Unable to upload file, please confirm the file is a .csv');
                    return;
                }
                Upload.upload(
                    {
                        url:'/api/data-import/upload-csv',
                        data:{file:file}
                    }
                )
                    .then(
                        function success(results)
                        {
                            alerts.success('Success', 'Data uploaded!');
                            $scope.uploading = false;
                            $scope.complete = 0;
                        },
                        function error(error)
                        {
                            alerts.error('Error', error.message);
                            $scope.uploading = false;
                            $scope.complete = 0;
                        },
                        function progress(evt)
                        {
                            console.log(evt);
                            $scope.complete = parseInt(100.0 * evt.loaded / evt.total);
                        }
                    );
            };
        }
    ]);
})(window.dy_n_fly_scoreboard);