'use strict';


(function (app)
{

    app.factory('alerts', [
        'SweetAlert',
        function (SweetAlert)
        {
            var alerts = {};

            alerts.error = function(title, message, callback)
            {
                SweetAlert.swal(
                    {
                        title:title,
                        type:'error',
                        text:message,
                    },function(confirm)
                    {
                        if(confirm && callback != null)
                            callback();
                    });
            };

            alerts.success = function (title, message)
            {
                SweetAlert.swal(title, message, 'success');
            };
            alerts.confirm = function (title, message, yes_callback, no_callback)
            {
                SweetAlert.swal(
                    {
                        title:title,
                        type:'warning',
                        text:message,
                        showCancelButton:true,
                    },function(confirm)
                    {
                        if(confirm)
                        {
                            if(yes_callback != null)
                                yes_callback();
                        }
                        else
                            if(no_callback != null)
                                no_callback();
                    }
                )
            };
            alerts.loader = function ()
            {

            };

          
            return alerts;
        }
    ]);


})(window.dy_n_fly_scoreboard);