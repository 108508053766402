/**
 * Created by mwillard on 1/17/17.
 */
(function ()
{
    window.dy_n_fly_scoreboard = angular.module('dy_n_fly_scoreboard',
        [
            'ngRoute',
            'oitozero.ngSweetAlert',
            'CompiledTemplates',
            'smart-table',
            'ui.bootstrap',
            'ngFileUpload'
        ]).config([
        '$routeProvider', '$locationProvider', '$uibTooltipProvider',
        function ($routeProvider, $locationProvider, $uibTooltipProvider)
        {
            $routeProvider.when('/', {
                redirectTo:'/dashboard'
            });
            $routeProvider.when('/home', {
                redirectTo:'/dashboard'
            });
            $routeProvider.when('/dashboard', {
                templateUrl: '/templates/dashboard.html',
                controller: 'DashboardCtrl'
            });
            $routeProvider.when('/users', {
                templateUrl: '/templates/admin/users.html',
                controller: 'UsersCtrl'
            });
            $routeProvider.when('/data-upload',
            {
                templateUrl: '/templates/admin/file_upload.html',
                controller: 'FileUploadCtrl'
            });
            $routeProvider.when('/login', {
                templateUrl: '/templates/login.html',
                controller: 'LoginCtrl'
            });
            $routeProvider.when('/logout', {
                controller: [
                    '$rootScope','user',
                    function ($rootScope, user)
                    {
                        $rootScope.original_path = "/home";
                        user.revokeAuthentication();
                    }
                ],
                template: '<div class="container"><div class="col-xs-12 text-center"><h1>Please Wait</h1><p>We are revoking your authentication.</p></div></div>'
            });

            $routeProvider.otherwise({
                controller: [
                    '$scope', 'alerts', "$location", 'user',
                    function ($scope, alerts, $location, user)
                    {
                        alerts
                            .error('Page Not Found', 'Something went wrong!!! You got to this page, ' +
                                'but I do not know how serve it to you. Please contact your support ' +
                                'person.',
                                function ()
                                {
                                    if (user.authenticated)
                                    {
                                        $location.path('/home');
                                    } else
                                    {
                                        $scope.password_reset = false;
                                        $location.path('/login');
                                    }
                                });
                    }
                ],
                template: '<div></div>'
            });

            $locationProvider.html5Mode(true);
        }
    ])
})();